<template>
  <b-card>
    <title-name />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Stock_groups/elements/Title.vue'

export default {
  name: 'StockGroupForm',
  components: {
    BCard,
    TitleName,
  },
}
</script>
